/*@use 'styles/_colors.scss'; // assuming a styles directory under src/ */
/*@use '~nprogress/nprogress'; // loading a css file from the nprogress node module */

/*@import url(./styles/floating-label.css);*/

@font-face {
  font-family: 'papyrus-font';
  src: url('./fonts/ParchmentMf-Vqge.ttf?ug5hnh') format('truetype');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  /*margin: 0;
  padding: 0;*/
}

body {
  line-height: 1.4;
  font-family: "Source Sans Pro", sans-serif;
  font-style: 1.1rem;
  -webkit-font-smoothing: antialiased;
}

.row {
  margin-right: 0;
}

a, a:hover {
  text-decoration: none;
}

.logo-image .top-title {
  fill: #DBDADA;
}

.logo #title-first-part {
  fill: #dd3232 !important;
}

.logo-image .title-second-part {
  fill: #DBDADA;
}

.logo-image .sub-title {
  fill: #DBDADA;
}

.carousel-root {
  &.presentation-mode {
    height: 100vh;
    width: 100vw;

    & .carousel-slider {
      height: 100%;
      width: 100%;

      & .slider-wrapper {
        width: 100%;
        height: 100%;

        & .slider {
          width: 100%;
          height: 100%;

          & .my-slide {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

/* SHADOW */

.card-shadow {
  -webkit-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.2);
  -moz-box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.2);
  box-shadow: 4px 4px 8px 0px rgba(0,0,0,0.2);
}

/* TEXT */

.c11n-text-xs {
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.4545454545rem;
}

.c11n-text-sm {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4285714286rem;
}

.c11n-text-md {
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.4117647059rem;
}

.c11n-text-lg {
  font-size: 1.4375rem;
  font-weight: 400;
  line-height: 1.3913043478rem;
}

.c11n-text-xl {
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.1428571429rem;
}

/* ANIMATION*/

.bg-fade-in {
  -webkit-animation: bgFadeIn 1s ; /* Chrome, Safari, Opera */
  animation: bgFadeIn 1s ;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes bgFadeIn {
  from {opacity: 0.2;}
  to {opacity: 1;}
}

/* Standard syntax */
@keyframes bgFadeIn {
  from {opacity: 0.2;}
  to {opacity: 1;}
}

.bg-fade-out {
  -webkit-animation: bgFadeOut 2s ; /* Chrome, Safari, Opera */
  animation: bgFadeOut 2s ;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes bgFadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}

/* Standard syntax */
@keyframes bgFadeOut {
  from {opacity: 1;}
  to {opacity: 0;}
}